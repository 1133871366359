
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as benchmarksZbx6Ozx93Meta } from "/vercel/path0/pages/benchmark.vue?macro=true";
import { default as calculatoryWuDZccaB9Meta } from "/vercel/path0/pages/calculator.vue?macro=true";
import { default as _91stockMetricId_93MPyE32R9Y0Meta } from "/vercel/path0/pages/company/[stockMetricId].vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as dividendszeVBqieWlwMeta } from "/vercel/path0/pages/dividends.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as password_45reset6pLgh9teUjMeta } from "/vercel/path0/pages/password-reset.vue?macro=true";
import { default as portfolio_45newhMssH0kZ5GMeta } from "/vercel/path0/pages/portfolio-new.vue?macro=true";
import { default as portfolioek2jrdhiX7Meta } from "/vercel/path0/pages/portfolio.vue?macro=true";
import { default as benchmarkeBBRPgMAh6Meta } from "/vercel/path0/pages/public/benchmark.vue?macro=true";
import { default as calculatorupaiZ6yQ4AMeta } from "/vercel/path0/pages/public/calculator.vue?macro=true";
import { default as _91stockMetricId_93RpnyHlkRJ0Meta } from "/vercel/path0/pages/public/company/[stockMetricId].vue?macro=true";
import { default as purchaseen8RoHHJE3Meta } from "/vercel/path0/pages/purchase.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as screenerjECYKBKRlVMeta } from "/vercel/path0/pages/screener.vue?macro=true";
import { default as accountujSiEcXIUQMeta } from "/vercel/path0/pages/settings/account.vue?macro=true";
import { default as import_45dataPo0db17keCMeta } from "/vercel/path0/pages/settings/import-data.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as calculator91mYUr4AUoMeta } from "/vercel/path0/pages/tools/calculator.vue?macro=true";
import { default as unsubscribedZE1ohsoC9eMeta } from "/vercel/path0/pages/unsubscribed.vue?macro=true";
import { default as valuezOZVnQ3UQrMeta } from "/vercel/path0/pages/value.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    component: () => import("/vercel/path0/pages/admin/index.vue")
  },
  {
    name: "benchmark",
    path: "/benchmark",
    meta: benchmarksZbx6Ozx93Meta || {},
    component: () => import("/vercel/path0/pages/benchmark.vue")
  },
  {
    name: "calculator",
    path: "/calculator",
    meta: calculatoryWuDZccaB9Meta || {},
    component: () => import("/vercel/path0/pages/calculator.vue")
  },
  {
    name: "company-stockMetricId",
    path: "/company/:stockMetricId()",
    meta: _91stockMetricId_93MPyE32R9Y0Meta || {},
    component: () => import("/vercel/path0/pages/company/[stockMetricId].vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "dividends",
    path: "/dividends",
    meta: dividendszeVBqieWlwMeta || {},
    component: () => import("/vercel/path0/pages/dividends.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45reset6pLgh9teUjMeta || {},
    component: () => import("/vercel/path0/pages/password-reset.vue")
  },
  {
    name: "portfolio-new",
    path: "/portfolio-new",
    meta: portfolio_45newhMssH0kZ5GMeta || {},
    component: () => import("/vercel/path0/pages/portfolio-new.vue")
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: portfolioek2jrdhiX7Meta || {},
    component: () => import("/vercel/path0/pages/portfolio.vue")
  },
  {
    name: "public-benchmark",
    path: "/public/benchmark",
    meta: benchmarkeBBRPgMAh6Meta || {},
    component: () => import("/vercel/path0/pages/public/benchmark.vue")
  },
  {
    name: "public-calculator",
    path: "/public/calculator",
    meta: calculatorupaiZ6yQ4AMeta || {},
    component: () => import("/vercel/path0/pages/public/calculator.vue")
  },
  {
    name: "public-company-stockMetricId",
    path: "/public/company/:stockMetricId()",
    meta: _91stockMetricId_93RpnyHlkRJ0Meta || {},
    component: () => import("/vercel/path0/pages/public/company/[stockMetricId].vue")
  },
  {
    name: "purchase",
    path: "/purchase",
    meta: purchaseen8RoHHJE3Meta || {},
    component: () => import("/vercel/path0/pages/purchase.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "screener",
    path: "/screener",
    meta: screenerjECYKBKRlVMeta || {},
    component: () => import("/vercel/path0/pages/screener.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue"),
    children: [
  {
    name: "settings-account",
    path: "account",
    component: () => import("/vercel/path0/pages/settings/account.vue")
  },
  {
    name: "settings-import-data",
    path: "import-data",
    component: () => import("/vercel/path0/pages/settings/import-data.vue")
  }
]
  },
  {
    name: "tools-calculator",
    path: "/tools/calculator",
    meta: calculator91mYUr4AUoMeta || {},
    component: () => import("/vercel/path0/pages/tools/calculator.vue")
  },
  {
    name: "unsubscribed",
    path: "/unsubscribed",
    meta: unsubscribedZE1ohsoC9eMeta || {},
    component: () => import("/vercel/path0/pages/unsubscribed.vue")
  },
  {
    name: "value",
    path: "/value",
    meta: valuezOZVnQ3UQrMeta || {},
    component: () => import("/vercel/path0/pages/value.vue")
  }
]